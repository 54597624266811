export class PromiseAwaiter {

  // ---------------------------------------------------------------------------
  public static async wait(promises: Promise<any>[]): Promise<any> {
    const rawResponse = await Promise.allSettled(promises);
    const responses = rawResponse.map(res => {
      if (res.status === 'fulfilled') {
        return res.value;
      }

      return null;
    }) as PromiseFulfilledResult<any>[];

    return responses;
  }
}