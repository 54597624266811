import { AppReviews, Review } from '../interfaces/domain/reviews.modal';
import {
  AppCommentsResponseDto,
  AppRatingResponseDto,
} from '../interfaces/dtos/review.dto';
import { IReviewsService } from '../interfaces/services/reviews-service.interface';
import { DataFetchService } from './data-fetch.service';
import { ReviewsMapper } from './mappers/reviews-mapper';
import { PromiseAwaiter } from './promise-awaiter';

// ---------------------------------------------------------------------------
export class ReviewsService implements IReviewsService {
  private mapper: ReviewsMapper;
  private readonly config: any;

  // ---------------------------------------------------------------------------
  constructor(config: any) {
    this.config = config;
    this.mapper = new ReviewsMapper();
  }

  // ---------------------------------------------------------------------------
  public async getReviews(
    appId: string,
    page: number,
    size: number
  ): Promise<AppReviews> {
    try {
      const { appReviews, appComments } = this.config?.publicRuntimeConfig?.api;
      const reviewStatsUrl = `${appReviews}/${appId}`;
      const commentsUrl = `${appComments}/${appId}/${page}/${size}`;

      const promises = [
        DataFetchService.get<AppCommentsResponseDto>(commentsUrl),
        DataFetchService.get<AppRatingResponseDto>(reviewStatsUrl),
      ];

      const responses = await PromiseAwaiter.wait(promises);

      if (!responses) {
        console.error(`Unable to find reviews ${appId}`);
        return null;
      }

      const res = {
        count: responses[1].count,
        avgRating: responses[1].avgRating,
        rateDistribution: responses[1].rateDistribution,
        comments: responses[0].comments,
      };

      return this.mapper.map({
        ...res,
      });
    } catch (e) {
      console.error(`Failed to get reviews ${appId}. ${e.message}`);
      throw new Error('Failed to get reviews', { cause: e.message });
    }
  }

  // ---------------------------------------------------------------------------
  public async getAdditionalReviews(
    appId: string,
    page: number,
    size: number
  ): Promise<Review[]> {
    const { appComments } = this.config?.publicRuntimeConfig?.api;
    const commentsUrl = `${appComments}/${appId}/${page}/${size}`;
    const res = await DataFetchService.get<AppCommentsResponseDto>(commentsUrl);
    if (!res) {
      console.error(`Unable to find reviews ${appId}`);
      return null;
    }

    return res.comments as Review[];
  }
}
