import { IAppService } from '../interfaces/services/app-service.interface';
import { IRemindMeService } from '../interfaces/services/remind-me-service.interface';
import { IReviewsService } from '../interfaces/services/reviews-service.interface';
import { ISearchService } from '../interfaces/services/search-service.interface';
import { IServicesHandler } from '../interfaces/services/service-handler.interface';
import { AppService } from './app-service';
import { RemindMeService } from './remind-me-service';
import { ReviewsService } from './reviews-service';
import { RemoteSearchService } from './remote-search-service';
import { LocalSearchService } from './local-search-service';
import { CategoryPageService } from './category-page-service';
import { ICategoryPageService } from '../interfaces/services/category-page-service';

// -----------------------------------------------------------------------------
export class ServicesHandler {
  private static instance: IServicesHandler;

  public readonly reviewsService: IReviewsService;
  public readonly appService: IAppService;
  public readonly remindMeService: IRemindMeService;
  public readonly categoryPageService: ICategoryPageService;
  public readonly remoteSearchService: ISearchService;
  public readonly localSearchService: ISearchService;

  // ---------------------------------------------------------------------------
  constructor(config: any) {
    this.reviewsService = new ReviewsService(config);
    this.appService = new AppService(config);
    this.remindMeService = new RemindMeService(config);
    this.categoryPageService = new CategoryPageService(config);
    this.remoteSearchService = new RemoteSearchService(config);
    this.localSearchService = new LocalSearchService(config);
  }

  // ---------------------------------------------------------------------------
  public static getInstance(config: any): IServicesHandler {
    if (!this.instance) {
      this.instance = new ServicesHandler(config);
    }

    return this.instance;
  }
}