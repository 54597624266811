import { OwAppTile } from '../interfaces/domain/ow-app-tile.modal';
import { SearchResultDTO } from '../interfaces/dtos/search-result.dto';
import { ISearchService } from '../interfaces/services/search-service.interface';
import { DataFetchService } from './data-fetch.service';

// ---------------------------------------------------------------------------
export class RemoteSearchService implements ISearchService {
  private readonly config: any;

  // ---------------------------------------------------------------------------
  constructor(config: any) {
    this.config = config;
  }

  // ---------------------------------------------------------------------------
  public async getSearchItems(name: string): Promise<OwAppTile[]> {
    try {
      if (!name) {
        return [];
      };

      if (name?.length < 3) {
        return [];
      };

      const baseUrl  = this.config?.publicRuntimeConfig?.base?.baseUrl;

      const result = await DataFetchService.get<SearchResultDTO>(
        `${baseUrl}/next-api/search?q=${name}`
      );

      return result.searchResult as OwAppTile[];
    } catch (e) {
      console.error(`Failed to get search for: ${name}. ${e.message}`);
    }

    return [];
  }
}
