import { IRemindMeService, RemindMeParams } from '../interfaces/services/remind-me-service.interface';
import { DataFetchService } from './data-fetch.service';

export class RemindMeService implements IRemindMeService {
  private readonly config: any;

  // ---------------------------------------------------------------------------
  constructor(config: any) {
    this.config = config;
  }

  // ---------------------------------------------------------------------------
  public async sendRemindMe(params: RemindMeParams): Promise<boolean> {
    try {
      const { legacyApiBase } = this.config?.publicRuntimeConfig.base;
      const url = `${legacyApiBase}/mobile/remind-me`;
      const result = await DataFetchService.post(url, params);
      return result.status === 200;
    } catch (e) {
      return false;
    }
  }

}