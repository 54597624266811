import Utils from './utils';

export interface CacheSettings {
  revalidate?: number;
  tags?: string[];
}

// -----------------------------------------------------------------------------
export class DataFetchService {
  // ---------------------------------------------------------------------------
  public static async get<T>(
    url: string,
    cacheSettings: CacheSettings = null,
    withCredentials = false
  ): Promise<T> {
    let response: Response | null = null;
    const cacheRevalidation = cacheSettings?.revalidate ?? null;

    try {
      if (!Utils.isBrowser()) {
        // console.log(`Executing GET request ${url}`);
      }

      // if (DataCacheService.has(url)) {
      //   const { data } = DataCacheService.get(url) as IDataCacheObject;
      //   return data as T;
      // }

      response = await fetch(
        url,
        {
          cache: 'no-store',
          next: cacheRevalidation !== null ? {
            revalidate: cacheRevalidation,
            tags: cacheSettings?.tags
          } : null,
          credentials: withCredentials ? 'include' : 'same-origin'
        }
      );

      if (response.status === 500 || response.status === 404) {
        return null;
      }

      const string = await response.text();
      const json = string === '' ? '' : JSON.parse(string);

      return json;
    } catch (error) {
      console.log(`Error while performing fetch on URL: ${url} `, error);
      return null;

    } finally {
      if (!Utils.isBrowser()) {
        // console.log(
        //   `Executed GET request ${url}. Status Code: ${response?.status ?? 'None'}`
        // );
      }
    }
  }

  // ---------------------------------------------------------------------------
  public static async post(
    url: string,
    data: any,
    withCredentials = false
  ): Promise<any> {
    const start = performance.now();
    try {
      // console.log(`Executing POST request ${url}`);

      const response = await fetch(url, {
        method: 'POST',
        headers: new Headers({'content-type': 'application/json'}),
        body: JSON.stringify(data),
        credentials: withCredentials ? 'include' : 'same-origin'
      });

      const string = await response.text();
      if (string === 'ok') {
        return {
          status: 200
        };
      }
      const json = string === '' ? '' : JSON.parse(string);

      return json;
    } finally {
      const stop = performance.now();
      const duration = (stop - start);
      const rounded = Number(duration).toFixed(3);
      console.log(`Executed POST request ${url}. Operation took: ${rounded} ms`);
    }
  }
}