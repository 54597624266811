import {
  AppReviews,
  RateDistribution,
} from '../../interfaces/domain/reviews.modal';
import {
  AppCommentsDto,
  AppRatingStarsDto,
} from '../../interfaces/dtos/review.dto';

// -----------------------------------------------------------------------------
export class ReviewsMapper {
  // ---------------------------------------------------------------------------
  public map(reviewsDto: AppCommentsDto): AppReviews {
    if (!reviewsDto) {
      return null;
    }

    return {
      totalRateCount: Number(reviewsDto?.count) ?? 0,
      averageRate: parseFloat(reviewsDto?.avgRating?.toPrecision(2)) ?? 0,
      comments: reviewsDto.comments ?? [],
      rateDistribution: this.mapDistribution(
        reviewsDto?.rateDistribution,
        reviewsDto?.count
      ),
    };
  }

  // ---------------------------------------------------------------------------
  private mapDistribution(
    rateDist: AppRatingStarsDto,
    count: number = 0
  ): RateDistribution[] {
    if (!rateDist || Object.entries(rateDist)?.length === 0) {
      return [];
    }

    return Object.entries(rateDist).map(([key, val]) => {
      return {
        stars: key,
        percents: this.getPercentRatingForApp(
          count,
          val
        ),
      };
    }) as unknown as RateDistribution[];
  }

  // ---------------------------------------------------------------------------
  private getPercentRatingForApp(sum: number, count: number): number {
    if (sum === 0 || count === 0) {
      return 0;
    }

    const percentRating = (count / sum) * 100;
    return parseFloat(percentRating.toFixed(1));
  }
}
